import { sendApi } from ".";
import { T_check } from './check';

export type T_elevator = {
  address1: string;
  elevatorNo: string;
  mntCpnyNm: string;
  buldNm:string;
  elvtrKindNm:string;
  elvtrModel:string;
  lastInspctDe:string; //마지막 정기점검일시
  ratedCap:string; // 용량
};

export type T_elevatorGetListData = {};

export type T_elevatorGetListResult = {
  msg: string;
  isSuccess: boolean;
  elevatorList: T_elevator[];
};
export type T_elevatorGetListResult2 = {
  msg: string;
  isSuccess: boolean;
  elevatorList: T_elevator[];
  totalPage: number;
  total:number;
};

async function getList(data?: T_elevatorGetListData) {
  const response = await sendApi({
    method: "post",
    url: "/elev/list",
    data: {
      ...data,
    },
  });
  const elevatorList = response.data.data;
  delete response.data.data;
  const result: T_elevatorGetListResult = {
    ...response.data,
    elevatorList,
  };
  return result;
}

async function getListPage(data?: T_elevatorGetData2) {
  const response = await sendApi({
    method: "post",
    url: "/elev/listpage",
    data: {
      ...data,
    },
  });
  const elevatorList = response.data.data.list;
  const totalPage = response.data.data.pages;
  const total = response.data.data.total;
  delete response.data.data;
  const result: T_elevatorGetListResult2 = {
    ...response.data,
    elevatorList,
    totalPage,
    total
  };
  return result;
}

export type T_elevatorGetData = {
  no: string;
};
export type T_elevatorGetData2 = {
  page: number;
  pagesize: number;
};


export type T_elevatorGetResult = {
  msg: string;
  isSuccess: boolean;
  elevator: T_elevator | null;
};

async function get(data: T_elevatorGetData) {
  const response = await sendApi({
    method: "post",
    url: "/elev/list",
    data: {
      ...data,
    },
  });
  let elevator: T_elevator | null = null;
  for (let idx = 0; idx < response.data.data.length; idx++) {
    const selElevator = response.data.data[idx];
    if (selElevator.elevatorNo === data.no) {
      elevator = selElevator;
      break;
    }
  }
  delete response.data.data;
  const result: T_elevatorGetResult = {
    ...response.data,
    elevator,
  };
  return result;
}

export type T_checkGetCheckingListData = {};


export type T_checkGetCheckingListResult = {
  msg: string;
  isSuccess: boolean;
  checkingElevatorList: T_check[];
};

async function getCheckingList(data?: T_checkGetCheckingListData) {
  const response = await sendApi({
    method: "post",
    url: "/check/checkelevlist",
    data: {
      ...data,
    },
  });
  const checkingElevatorList = response.data.data;
  delete response.data.data;
  const result: T_checkGetCheckingListResult = {
    ...response.data,
    checkingElevatorList,
  };
  return result;
}
const elevator = {
  getList,
  get,
  getCheckingList,
  getListPage,
};

export default elevator;
