/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

==========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MyMDBox from "../../components/MyMDBox";
import MyMDButton from "../../components/MyMDButton";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import { useCallback, useEffect, useState } from "react";
import MyMDTypography from "../../components/MyMDTypography";
import useNav from "../../uses/useNav";
import apis from "../../apis";
import MyEdit from "../../examples/MyEdit";
import MyMDInput from "../../components/MyMDInput";
import MyMDSelect from "../../components/MyMDSelect";
import { useUser } from "../../contexts/user";
import { Navigate } from "react-router-dom";

type T_selectGroup = {
  name: string;
  value: string;
};

function ElevatorDetailLayout() {
  const nav = useNav();
  const { user } = useUser();
  const no = nav.pathname.split("/elevator/")[1];
  const isCreate = nav.pathname.includes("/elevator/create");
  const [isInit, setIsInit] = useState(false);
  const [elevatorNm, setElevatorNm] = useState("");
  const [elevatorNo, setElevatorNo] = useState("");
  const [group, setGroup] = useState<T_selectGroup | null>(null);
  const [memo, setMemo] = useState("");
  const [groupList, setGroupList] = useState<T_selectGroup[] | null>([]);

  const getGroupList = useCallback(async () => {
    try {
      const result = await apis.group.getList();
      if (result.isSuccess) {
        const groupList = result.groupList;
        const selectGroupList: T_selectGroup[] = [];
        for (let idx = 0; idx < groupList.length; idx++) {
          const groupItem = groupList[idx];
          selectGroupList.push({
            name: groupItem.groupNm,
            value: groupItem.groupIdx,
          });
        }
        setGroupList(selectGroupList);
        setGroup(selectGroupList[0]);
      } else {
      }
    } catch (err: any) {
      console.log(err.message);
    }
  }, []);

  const getElevator = useCallback(async () => {
    try {
      const result = await apis.elevator.get({
        no,
      });
      if (result.isSuccess && result.elevator) {
        const elevator = result.elevator;
        setElevatorNo(elevator.elevatorNo);
        setElevatorNm(elevator.buldNm);
        setMemo("");
      } else {
        console.log(result.msg);
      }
    } catch (err: any) {
      console.log(err.message);
    }
  }, [no]);

  useEffect(() => {
    if (!isInit) {
      const init = async () => {
        await getGroupList();
        if (!isCreate) {
          await getElevator();
        }
        setIsInit(true);
      };
      init();
    }
  }, [isCreate, getElevator, isInit, getGroupList]);

  if (!user) {
    return <Navigate replace to={`/signin`} />;
  } else {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        {isInit && (
          <>
            <MyEdit
              header={{
                title: "엘리베이터 정보",
              }}
            >
              <MyMDBox
                mt={1.5}
                display="flex"
                sx={{
                  alignItems: "center",
                }}
              >
                <MyMDTypography minWidth={120} variant="caption" color="text" fontWeight="medium">
                  엘리베이터 번호
                </MyMDTypography>
                <MyMDInput
                  onChange={(evt: any) => {
                    setElevatorNo(evt.target.value);
                  }}
                  fullWidth={true}
                  value={elevatorNo}
                  label="No"
                  type="text"
                />
                <MyMDBox ml={1}>
                  <MyMDButton
                    component="a"
                    rel="noreferrer"
                    variant="gradient"
                    color="secondary"
                    href={``}
                    sx={{
                      width: 130,
                    }}
                    onClick={(evt: any) => {
                      evt.preventDefault();
                    }}
                  >
                    정보가져오기
                  </MyMDButton>
                </MyMDBox>
              </MyMDBox>
              <MyMDBox
                mt={1.5}
                display="flex"
                sx={{
                  alignItems: "center",
                }}
              >
                <MyMDTypography minWidth={120} variant="caption" color="text" fontWeight="medium">
                  이름
                </MyMDTypography>
                <MyMDInput
                  onChange={(evt: any) => {
                    setElevatorNm(evt.target.value);
                  }}
                  fullWidth={true}
                  value={elevatorNm}
                  label="Name"
                  type="text"
                />
              </MyMDBox>
              <MyMDBox
                mt={1.5}
                display="flex"
                sx={{
                  alignItems: "center",
                }}
              >
                <MyMDTypography minWidth={120} variant="caption" color="text" fontWeight="medium">
                  그룹
                </MyMDTypography>
                <MyMDSelect
                  label="Group"
                  value={group ? group.value : ""}
                  itemList={groupList || []}
                  onChange={(value) => {
                    if (groupList) {
                      for (let idx = 0; idx < groupList.length; idx++) {
                        const tempGroup = groupList[idx];
                        if (value === tempGroup.value) {
                          setGroup(tempGroup);
                          break;
                        }
                      }
                    }
                  }}
                />
              </MyMDBox>
              <MyMDBox
                mt={1.5}
                display="flex"
                sx={{
                  alignItems: "center",
                }}
              >
                <MyMDTypography minWidth={120} variant="caption" color="text" fontWeight="medium">
                  메모
                </MyMDTypography>
                <MyMDInput
                  onChange={(evt: any) => {
                    setMemo(evt.target.value);
                  }}
                  fullWidth={true}
                  multiline={true}
                  value={memo}
                  label="Memo"
                  type="text"
                  rows={10}
                />
              </MyMDBox>
              <MyMDBox mt={5} justifyContent="center" display="flex">
                {!isCreate && (
                  <MyMDBox pr={1}>
                    <MyMDButton
                      component="a"
                      rel="noreferrer"
                      variant="gradient"
                      color="error"
                      href={``}
                      sx={{
                        width: 100,
                      }}
                      onClick={(evt: any) => {
                        evt.preventDefault();
                        nav.goBack();
                      }}
                    >
                      삭제
                    </MyMDButton>
                  </MyMDBox>
                )}
                <MyMDBox pr={1}>
                  <MyMDButton
                    component="a"
                    rel="noreferrer"
                    variant="gradient"
                    color="info"
                    href={``}
                    sx={{
                      width: 100,
                    }}
                    onClick={(evt: any) => {
                      evt.preventDefault();
                      nav.goBack();
                    }}
                  >
                    {isCreate ? "등록" : "수정"}
                  </MyMDButton>
                </MyMDBox>
                <MyMDBox>
                  <MyMDButton
                    component="a"
                    rel="noreferrer"
                    variant="gradient"
                    color="secondary"
                    href={``}
                    sx={{
                      width: 100,
                    }}
                    onClick={(evt: any) => {
                      evt.preventDefault();
                      nav.goBack();
                    }}
                  >
                    취소
                  </MyMDButton>
                </MyMDBox>
              </MyMDBox>
            </MyEdit>
            <Footer />
          </>
        )}
      </DashboardLayout>
    );
  }
}

export default ElevatorDetailLayout;
